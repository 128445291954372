import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import * as i1 from '@angular/platform-browser';
const isValidMetaContent = content => {
  return content !== null && content !== undefined && content !== '';
};
class ContentSeoService {
  constructor(title, meta) {
    this.title = title;
    this.meta = meta;
  }
  setMeta(meta, title = null, publishedAt = null) {
    if (title) {
      this.title.setTitle(title);
    }
    if (!meta) {
      return;
    }
    this.updateTag({
      name: 'description',
      content: meta.description
    });
    this.updateTag({
      name: 'keywords',
      content: meta.keywords
    });
    this.updateTag({
      name: 'og:title',
      property: 'og:title',
      content: meta.title
    });
    this.updateTag({
      name: 'og:image',
      property: 'og:image',
      content: meta.image?.url
    });
    this.updateTag({
      name: 'og:image:secure_url',
      property: 'og:image:secure_url',
      content: meta.image?.url
    });
    this.updateTag({
      name: 'og:description',
      property: 'og:description',
      content: meta.description
    });
    this.updateTag({
      name: 'og:site_name',
      property: 'og:site_name',
      content: meta.siteName
    });
    this.updateTag({
      name: 'twitter:site',
      content: meta.siteName
    });
    this.updateTag({
      name: 'twitter:title',
      content: meta.title
    });
    this.updateTag({
      name: 'twitter:description',
      content: meta.description
    });
    this.updateTag({
      name: 'twitter:image',
      content: meta.image?.url
    });
    this.updateTag({
      name: 'article:published_time',
      property: 'article:published_time',
      content: publishedAt
    });
  }
  updateTag(tag) {
    if (!isValidMetaContent(tag.content)) {
      return;
    }
    this.meta.updateTag({
      ...tag,
      content: tag.content
    });
  }
  static {
    this.ɵfac = function ContentSeoService_Factory(t) {
      return new (t || ContentSeoService)(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i1.Meta));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ContentSeoService,
      factory: ContentSeoService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ContentSeoService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Title
  }, {
    type: i1.Meta
  }], null);
})();
const simpleContentPage = (path, callback) => {
  return {
    path,
    loadComponent: () => import('./trendency-simple-page-content.page-CBDdPkoD.mjs').then(c => c.ContentPage),
    resolve: {
      content: (route, state) => {
        const content$ = callback(route, state);
        return content$;
      }
    },
    data: {
      isSimpleContentPage: true
    }
  };
};

/**
 * Generated bundle index. Do not edit.
 */

export { ContentSeoService, simpleContentPage };
